import { useCallback, useEffect, useState } from 'react'
import { searchStories } from '../../../../helpers/parsedTree.js'
import { type SearchResultItem } from '../../../atoms/searchResults.js'
import { useDebounceText } from '../../../hooks/useDebounceText.js'

export function useSearchStories(props: { onClose: () => void }) {
  const { onClose } = props

  const [search, setSearch] = useState('')

  const [searchResults, setSearchResults] = useState<SearchResultItem[]>([])

  const handleOnChange = useCallback((value: string) => {
    setSearch(value)
  }, [])

  const [searchValue, onChangeSearchValue, resetSearch] = useDebounceText('', handleOnChange, 300)

  const handleOnClose = useCallback(() => {
    onClose()
    setSearch('')
    resetSearch()
  }, [onClose, resetSearch])

  useEffect(() => {
    const matches = search.trim() !== '' ? searchStories(search) : []
    setSearchResults(matches)
  }, [search])

  const noResults = search.trim() !== '' && searchResults.length === 0

  return {
    noResults,
    searchValue,
    searchResults,
    handleOnClose,
    onChangeSearchValue,
  }
}
