import { ListSubheader } from '@mui/material'

import { type SearchResultItemCategory } from '../../../atoms/searchResults.js'

type Props = {
  item: SearchResultItemCategory
}

export function SearchCategory(props: Props) {
  const {
    item: { path },
  } = props

  return <ListSubheader>{path}</ListSubheader>
}
