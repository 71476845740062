import { useMemo } from 'react'

import { type TidalStories, type TidalStoriesApi } from '@workwave-tidal/stories-helpers'

import { storyExists } from '../../helpers/parsedTree.js'
import {
  setGlobalControlsConfig,
  updateGlobalControlsConfig,
  useSetTidalStoriesStateField,
  useTidalStoriesState,
} from '../atoms/storiesState.js'
import { useChangeStory } from './useChangeStory.js'

export function useCreateTidalStoriesContext(): {
  tidalStories: TidalStories
  tidalStoriesApi: TidalStoriesApi
} {
  const changeActiveStory = useChangeStory()

  const tidalStories = useTidalStoriesState()

  const setNavigationCollapsed = useSetTidalStoriesStateField('navigationCollapsed')
  const setDisableKeybindings = useSetTidalStoriesStateField('disableKeybindings')
  const setSidebarPosition = useSetTidalStoriesStateField('sidebarPosition')
  const setFullScreen = useSetTidalStoriesStateField('fullScreen')
  const setNavigationWidth = useSetTidalStoriesStateField('navigationWidth')
  const setNavigationDisabled = useSetTidalStoriesStateField('navigationDisabled')

  const tidalStoriesApi = useMemo<TidalStoriesApi>(() => {
    return {
      setActiveStory: (slug: string) => {
        if (storyExists(slug)) changeActiveStory(slug)
      },
      setDisableKeybindings,
      setFullScreen,
      setNavigationCollapsed,
      setNavigationDisabled,
      setNavigationWidth,
      setSidebarPosition,
      updateGlobalControlsConfig,
      setGlobalControlsConfig,
    }
  }, [
    changeActiveStory,
    setNavigationCollapsed,
    setDisableKeybindings,
    setSidebarPosition,
    setFullScreen,
    setNavigationWidth,
    setNavigationDisabled,
  ])

  return { tidalStories, tidalStoriesApi }
}
