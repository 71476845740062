import { Stack } from '@mui/material'

import { getBottomComponents, getLeftComponents, getRightComponents, getTopComponents } from '../helpers/parsedTree.js'

import { Grid } from './Grid.js'
import { Navigation } from './Navigation/Navigation.js'
import { Preview } from './Preview.js'
import { useTidalStoriesStateField } from './atoms/storiesState.js'

export function Layout() {
  const [sidebarPosition, setSidebarPosition] = useTidalStoriesStateField('sidebarPosition')

  return (
    <Grid
      left={getLeftComponents()}
      top={getTopComponents()}
      right={getRightComponents()}
      bottom={getBottomComponents()}
    >
      <Stack
        direction={sidebarPosition}
        sx={{
          width: '100%',
          maxHeight: '100%',
          height: '100%',
          overflow: 'hidden',
          margin: 0,
          padding: 0,
        }}
      >
        <Navigation sidebarPosition={sidebarPosition} onChangeSidebarPosition={setSidebarPosition} />
        <Preview />
      </Stack>
    </Grid>
  )
}
