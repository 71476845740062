import { SearchOff as SearchOffIcon } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'

export function NoResults(props: { search: string }) {
  const { search } = props

  return (
    <Stack sx={{ alignItems: 'center', height: '100%' }}>
      <SearchOffIcon sx={{ mt: 'auto', fontSize: '72px' }} />

      <Typography variant="body1" sx={{ mb: 'auto' }}>
        No results for &quot;
        <Typography variant="body1" component="span" sx={{ fontWeight: 'bold' }}>
          {search}
        </Typography>
        &quot;.
      </Typography>
    </Stack>
  )
}
