import { List, Typography } from '@mui/material'
import { type SearchResultItemStory } from '../../../atoms/searchResults.js'
import { SearchStory, type SearchStoryProps } from './SearchStory.js'

type Props = {
  title: string
  results: SearchResultItemStory[]
  variant?: SearchStoryProps['variant']
  noResults?: string
  onCloseSearchDialog: () => void
  navigationDisabled?: boolean
  activeItemId?: string
}

export function ResultCollection(props: Props) {
  const { title, results, variant, noResults, onCloseSearchDialog, navigationDisabled, activeItemId } = props

  const showNoResults = results.length === 0 && !!noResults

  return (
    <>
      <Typography variant="h6">{title}</Typography>

      {showNoResults && <Typography>{noResults}</Typography>}

      <List>
        {results.map(item => (
          <SearchStory
            key={item.id}
            item={item}
            active={activeItemId === item.id}
            variant={variant}
            onCloseSearchDialog={onCloseSearchDialog}
            navigationDisabled={navigationDisabled}
          />
        ))}
      </List>
    </>
  )
}
