import { styled } from '@mui/material'
import { type ReactElement, type ReactNode } from 'react'

import { GlobalToolbar } from '@workwave-tidal/stories-helpers'

interface Props {
  top?: ReactElement[]
  left?: ReactElement[]
  bottom?: ReactElement[]
  right?: ReactElement[]
  children?: ReactNode
}

export function Grid(props: Props) {
  const { children, top, bottom, right, left } = props

  return (
    <GridContainer>
      {top && (
        <div data-id="tidal-stories-top" className="top">
          <GlobalToolbar placement="top">{top}</GlobalToolbar>
        </div>
      )}

      {left && (
        <div data-id="tidal-stories-left" className="left">
          <GlobalToolbar placement="left">{left}</GlobalToolbar>
        </div>
      )}

      {bottom && (
        <div data-id="tidal-stories-bottom" className="bottom">
          <GlobalToolbar placement="bottom">{bottom}</GlobalToolbar>
        </div>
      )}

      {right && (
        <div data-id="tidal-stories-right" className="right">
          <GlobalToolbar placement="right">{right}</GlobalToolbar>
        </div>
      )}

      {children && (
        <div data-id="tidal-stories-content" className="content">
          {children}
        </div>
      )}
    </GridContainer>
  )
}

const GridContainer = styled('div')(() => {
  return {
    display: 'grid',
    height: '100vh',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateRows: 'auto 1fr auto',
    gridTemplateAreas: `
      "left top right"
      "left content right"
      "left bottom right"
      `,

    '& .top': {
      gridArea: 'top',
    },

    '& .left': {
      gridArea: 'left',
    },

    '& .bottom': {
      gridArea: 'bottom',
    },

    '& .right': {
      gridArea: 'right',
    },

    '& .content': {
      gridArea: 'content',
      overflow: 'hidden',
    },
  }
})
