import { useLocation } from '@workwave-tidal/router'
import { findItemById } from '../../helpers/parsedTree.js'
import { useSnackMachine } from './useSnackMachine.js'

export function useRedirectLegacyStoryUrl() {
  const [, navigate] = useLocation()
  const snackMachine = useSnackMachine()

  // ATTENTION: remap legacy URLs
  // We want to continue to support legacy URLs like: "#/?story=components__ActionBar__advanced__Modal"
  // When we encounter one of these URLs, we want to redirect to the new URL format: "/components/modal-i32hkw"
  const hash = document.location.hash

  if (hash) {
    const queryParamsIndex = hash.indexOf('?')
    const hasQueryParams = queryParamsIndex !== -1

    if (!hasQueryParams) return

    const queryParams = hash
      .slice(queryParamsIndex + 1)
      .split('&')
      .map(param => param.split('='))

    const storyParamIndex = queryParams.findIndex(param => param[0] === 'story')
    if (storyParamIndex === -1) return

    // const storyUID = queryParams.find(param => param[0] === 'story')?.[0]?.[1]
    const storyUID = queryParams[storyParamIndex]?.[1]

    if (storyUID) {
      const targetStory = findItemById(storyUID)

      if (targetStory) {
        const oldUrl = document.location.toString()

        // remove the story param
        queryParams.splice(storyParamIndex, 1)
        // compose them into a URL valid string
        const otherParamsAsString = queryParams.length ? `?${queryParams.map(p => p.join('=')).join('&')}` : ''

        navigate(`/${targetStory.slug}${otherParamsAsString}`, { replace: true })

        console.warn(
          `You have been redirected to the new URL for the story: "${targetStory.title}". Unique ID: [${targetStory.uid}]`,
        )
        console.warn(`Old URL: ${oldUrl}`)

        snackMachine.addSnack({
          message: `You have been redirected to the new URL Format.
Please update your bookmarks.`,
          severity: 'info',
          autoHideDuration: 10000,
        })
      }
    }
  }
}
