import { type SearchResultItemStory } from '../../../atoms/searchResults.js'
import { ResultCollection } from './ResultCollection.js'

type Props = {
  items: SearchResultItemStory[]
  onCloseSearchDialog: () => void
  navigationDisabled?: boolean
  activeItemId?: string
}

export function FavoriteResults(props: Props) {
  const { onCloseSearchDialog, navigationDisabled, items, activeItemId } = props

  return (
    <ResultCollection
      title="Favorites"
      results={items}
      variant="favorite"
      noResults="Favorited results will appear here."
      onCloseSearchDialog={onCloseSearchDialog}
      navigationDisabled={navigationDisabled}
      activeItemId={activeItemId}
    />
  )
}
