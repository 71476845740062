import { LicenseInfo } from '@mui/x-license-pro'

// We don't know if the global variable will be injected into the bundler process
// to avoid unexpected errors we access it on its own
let licenseFromEnv: string | undefined

try {
  // biome-ignore lint/correctness/noUndeclaredVariables:
  licenseFromEnv = __WW_MUI_LICENSE_KEY__
} catch (e) {
  //
}

// try to access the .env.* files provided license or the ENV provided one
try {
  const license = import.meta.env.VITE_MUI_LICENSE_KEY ?? licenseFromEnv

  if (import.meta.env.DEV) {
    license ? console.log(`MUI License found!`) : console.warn(`No MUI License found!`)
  }

  if (license) {
    LicenseInfo.setLicenseKey(license)
  }
} catch (e) {
  //
}
