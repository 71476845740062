import { ChevronRight as ChevronRightIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { TreeView } from '@mui/x-tree-view'
import { useConstant } from '@workwave-tidal/hooks'
import { useMemo } from 'react'

import { expandToSlug } from '../atoms/treeState.js'
import { useExpandedNodes } from '../hooks/useExpandedNodes.js'
import { useSelectedNodes } from '../hooks/useSelectedNodes.js'
import { useStoryRoute } from '../hooks/useStoryRoute.js'

import { findBySlugWithPath, getStoriesTree } from '../../helpers/parsedTree.js'

import { StoryOrNode } from './StoryOrNode.js'

type Props = {
  navigationDisabled?: boolean
}
export function NavigationTree(props: Props) {
  const { navigationDisabled } = props
  const { story, filter } = useStoryRoute()

  useConstant(() => {
    expandToSlug(story ? story : filter)
  })

  const stories = useMemo(() => {
    if (filter) {
      const foundNode = findBySlugWithPath(filter)

      if (foundNode) {
        const { item, path } = foundNode

        // overwrite label w/ folder path
        const title = path.map(x => x.title).join('/')

        return {
          [filter]: {
            ...item,
            title,
          },
        }
      }
    }
    return getStoriesTree()
  }, [filter])

  const { activeStory, onSelectStory } = useSelectedNodes()
  const { expanded, onToggle } = useExpandedNodes()

  return (
    <TreeView
      sx={{ marginBottom: '100px' }}
      selected={activeStory ?? ''}
      aria-label="controlled"
      expanded={expanded}
      multiSelect={false}
      onNodeSelect={onSelectStory}
      onNodeToggle={onToggle}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultCollapseIcon={<ExpandMoreIcon />}
    >
      {Object.values(stories).map(storyRoot => (
        <StoryOrNode item={storyRoot} key={`__${storyRoot.uid}`} navigationDisabled={navigationDisabled} />
      ))}
    </TreeView>
  )
}
