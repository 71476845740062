import { usePreviewTheme } from '@workwave-tidal/devtools'
import { useToggle } from '@workwave-tidal/hooks'
import { type TidalThemeProviderProps } from '@workwave-tidal/tidal/styles'
import { ChangeEvent, MouseEvent, useCallback, useState } from 'react'
import { setActiveTheme, useActiveTheme } from '../themeStore.js'

type ThemeVersion = TidalThemeProviderProps['themeVersion']

export function useThemeSidebar() {
  const themeVersion = useActiveTheme()
  const [sidebarOpen, onToggleSidebar] = useToggle(false)

  const [versionMenuAnchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const versionMenuOpen = Boolean(versionMenuAnchorEl)

  const {
    pristine,
    api: { setTheme },
  } = usePreviewTheme()

  const themeModified = !pristine

  const onOpenVersionMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const onCloseVersionMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const onResetTheme = useCallback(() => {
    setTheme({})
  }, [setTheme])

  const onChangeThemeVersion = useCallback((_event: ChangeEvent<HTMLInputElement>, value: string) => {
    setActiveTheme(value as ThemeVersion)
    setAnchorEl(null)
  }, [])

  return {
    themeVersion,
    themeModified,
    onResetTheme,
    //
    onToggleSidebar,
    sidebarOpen,
    //
    versionMenuAnchorEl,
    versionMenuOpen,
    onOpenVersionMenu,
    onCloseVersionMenu,
    onChangeThemeVersion,
  }
}
