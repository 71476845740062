import { type SyntheticEvent, useCallback } from 'react'

import { setExpandedNodes, useExpandedNodeState } from '../atoms/treeState.js'

export function useExpandedNodes() {
  const expanded = useExpandedNodeState() as string[]

  const onToggle = useCallback((_: SyntheticEvent, storyIds: string[]) => {
    setExpandedNodes(storyIds)
  }, [])

  return { expanded, onToggle } as const
}
