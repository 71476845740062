import { useCallback, useState } from 'react'

export function useSearchDialogVisibility() {
  const [searchDialogVisible, setShowSearchDialog] = useState(false)
  const onShowSearchDialog = useCallback(() => {
    setShowSearchDialog(true)
  }, [])

  const onCloseSearchDialog = useCallback(() => {
    setShowSearchDialog(false)
  }, [])

  return {
    searchDialogVisible,
    onCloseSearchDialog,
    onShowSearchDialog,
  }
}
