import { useEffect, useMemo, useRef, useState } from 'react'

import { type SearchResultItem, type SearchResultItemStory } from '../../../atoms/searchResults.js'

interface Params {
  search: SearchResultItem[]
  favorites: SearchResultItemStory[]
  recent: SearchResultItemStory[]
  navigationDisabled?: boolean
  dialogOpen: boolean
}

export function useKeyboardNavigation(params: Params) {
  const { search, favorites, recent, navigationDisabled, dialogOpen } = params

  const [active, setActive] = useState<string | undefined>(() => undefined)
  const activeIndexRef = useRef<number>(-1)

  const collection = useMemo(() => {
    if (search.length > 0) {
      return search.filter(item => item.type === 'item')
    }

    return [...favorites, ...recent]
  }, [search, favorites, recent])

  useEffect(() => {
    setActive(prevActive => {
      const activeElement = collection.find(item => item.id === prevActive)

      if (activeElement) return prevActive

      activeIndexRef.current = -1
      return undefined
    })
  }, [collection])

  // biome-ignore lint/correctness/useExhaustiveDependencies: The deps are all satisfied. Biome is being picky here.
  useEffect(() => {
    function getNext() {
      const nextIndex = activeIndexRef.current + 1
      const index = nextIndex < collection.length ? nextIndex : 0

      activeIndexRef.current = index
      return collection[index].id
    }

    function getPrev() {
      const prevIndex = activeIndexRef.current - 1
      const index = prevIndex >= 0 ? prevIndex : collection.length - 1

      activeIndexRef.current = index
      return collection[index].id
    }

    const callback = (event: KeyboardEvent) => {
      if (navigationDisabled) return

      switch (event.key) {
        case 'ArrowUp':
          setActive(getPrev())
          break
        case 'ArrowDown':
          setActive(getNext())
          break
      }
    }

    window.addEventListener('keydown', callback)

    return () => window.removeEventListener('keydown', callback)
  }, [collection, navigationDisabled])

  useEffect(() => {
    if (dialogOpen) return

    setActive(undefined)
    activeIndexRef.current = -1
  }, [dialogOpen])

  return {
    active,
  }
}
