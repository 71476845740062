import { proxy, subscribe, useSnapshot } from '@workwave-tidal/store'
import { findBySlugWithPath, getStoryIterator } from '../../helpers/parsedTree.js'

const localStorageId = 'tidal-stories/tree-state'

type TreeState = {
  expandedNodes: string[]
}

type TreeStateAtom = {
  state: TreeState
  version: number
}

const defaultTreeState: TreeState = {
  expandedNodes: [],
}

export const treeStateStore = proxy<TreeStateAtom>({
  state: defaultTreeState,
  version: 1,
})

//////////////////////////////
// #region local storage    //
//////////////////////////////

function loadFromStorage() {
  const item = localStorage.getItem(localStorageId)

  if (item) {
    try {
      const stored = JSON.parse(item) as TreeStateAtom

      if (stored.version === 1) {
        treeStateStore.state = stored.state
      }
    } catch {
      //
    }
  }
}

function saveToStorage(atom: TreeStateAtom) {
  localStorage.setItem(localStorageId, JSON.stringify(atom))
}

loadFromStorage()

subscribe(treeStateStore, () => {
  saveToStorage(treeStateStore)
})

//////////////////////////////
// #endregion local storage //
//////////////////////////////

export function setExpandedNodes(value: string[]) {
  treeStateStore.state.expandedNodes = value
}

export function collapseAll() {
  setExpandedNodes([])
}

export function expandAll() {
  setExpandedNodes([...getStoryIterator()].filter(x => x.type === 'node').map(x => x.uid))
}

export function expandToSlug(slug?: string) {
  if (slug) {
    const result = findBySlugWithPath(slug)

    const activePath = result?.path.map(x => x.uid) ?? []

    setExpandedNodes(activePath)
  }
}

export function useExpandedNodeState() {
  return useSnapshot(treeStateStore.state).expandedNodes
}
