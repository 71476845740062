import { useCallback, useLayoutEffect, useRef } from 'react'

import {
  DoubleArrow as DoubleArrowIcon,
  FormatTextdirectionLToR as LToRIcon,
  FormatTextdirectionRToL as RToLIcon,
  Fullscreen as FullscreenIcon,
  MenuOpen as MenuOpenIcon,
  UnfoldLess as UnfoldLessIcon,
  UnfoldMore as UnfoldMoreIcon,
} from '@mui/icons-material'
import { Button, Stack, Tooltip } from '@mui/material'

import { type UpdateFunction } from '@workwave-tidal/hooks/useQueryState'

import { collapseAll, expandAll, expandToSlug, useExpandedNodeState } from '../atoms/treeState.js'
import { useStoryRoute } from '../hooks/useStoryRoute.js'

export type Props = {
  sidebarPosition: 'row' | 'row-reverse'
  setCollapsed: UpdateFunction<boolean>
  setFullScreen: UpdateFunction<boolean>
  onChangeSidebarPosition: UpdateFunction<'row' | 'row-reverse'>
}

export function NavigationControls(props: Props) {
  const { setCollapsed, setFullScreen, sidebarPosition, onChangeSidebarPosition } = props

  const rSidebarPosition = useRef(sidebarPosition)
  useLayoutEffect(() => void (rSidebarPosition.current = sidebarPosition), [sidebarPosition])

  const handleSidebarPosition = useCallback(() => {
    onChangeSidebarPosition(rSidebarPosition.current === 'row' ? 'row-reverse' : 'row')
  }, [onChangeSidebarPosition])

  const handleEnterFullScreen = useCallback(() => {
    setFullScreen(true)
  }, [setFullScreen])

  const handleCollapseSidebar = useCallback(() => {
    setCollapsed(true)
  }, [setCollapsed])

  const { story } = useStoryRoute()
  const onClickGoToStory = useCallback(() => {
    expandToSlug(story)
  }, [story])

  const nodes = useExpandedNodeState()
  const anyExpanded = nodes.length > 0
  const expColIcon = anyExpanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />
  const expColLabel = `${anyExpanded ? 'Collapse' : 'Expand'} all`
  const onClickExpCol = anyExpanded ? collapseAll : expandAll

  return (
    <Stack
      sx={{
        mb: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 0,
      }}
      direction={sidebarPosition}
    >
      <Tooltip title="Collapse sidebar">
        <Button onClick={handleCollapseSidebar}>
          <DoubleArrowIcon
            sx={{
              transform: sidebarPosition === 'row' ? 'scale(-1, 1)' : 'scale(1, 1)',
            }}
          />
        </Button>
      </Tooltip>

      <Tooltip title="Toggle full screen">
        <Button onClick={handleEnterFullScreen}>
          <FullscreenIcon />
        </Button>
      </Tooltip>

      <Tooltip title={expColLabel}>
        <Button onClick={onClickExpCol}>{expColIcon}</Button>
      </Tooltip>

      <Tooltip title="Go to current story">
        <Button onClick={onClickGoToStory}>
          <MenuOpenIcon />
        </Button>
      </Tooltip>

      <Tooltip title="Change sidebar position">
        <Button onClick={handleSidebarPosition}>{sidebarPosition === 'row' ? <LToRIcon /> : <RToLIcon />}</Button>
      </Tooltip>
    </Stack>
  )
}
