import { Box, Skeleton } from '@mui/material'

export function PreviewFallback() {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ width: 300 }}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
    </Box>
  )
}
