import { useMotionValue } from 'framer-motion'
import { useLayoutEffect, useRef } from 'react'
import { useTidalStoriesStateField } from '../atoms/storiesState.js'

export function useResizeNavigation(sidebarPosition: 'row' | 'row-reverse') {
  const rSidebarPosition = useRef(sidebarPosition)
  useLayoutEffect(() => void (rSidebarPosition.current = sidebarPosition), [sidebarPosition])

  const [navigationWidth, setNavigationWidth] = useTidalStoriesStateField('navigationWidth')
  const mWidth = useMotionValue(navigationWidth)

  useLayoutEffect(() => {
    if (navigationWidth !== mWidth.get()) {
      mWidth.set(navigationWidth)
    }
  }, [mWidth, navigationWidth])

  return { mWidth, setNavigationWidth } as const
}
