import { useLocation } from '@workwave-tidal/router'
import { useCallback } from 'react'

import { getTidalStoriesValue } from '../atoms/storiesState.js'
import { useStoryRoute } from './useStoryRoute.js'

export function useChangeStory() {
  const { filter } = useStoryRoute()
  const [, navigate] = useLocation()

  return useCallback(
    (slug: string) => {
      const navigationDisabled = getTidalStoriesValue('navigationDisabled')
      if (navigationDisabled) return

      // FIXME:
      // ATTENTION: this won't work once we disable hash navigation for tidal preview
      const hash = document.location.hash
      const queryParamsIndex = hash.indexOf('?')
      const hasQueryParams = queryParamsIndex !== -1
      const queryParams = hasQueryParams ? hash.slice(queryParamsIndex) : ''

      if (filter) {
        navigate(`/${filter}/${slug}${queryParams}`)
      } else {
        navigate(`/${slug}${queryParams}`)
      }
    },
    [filter, navigate],
  )
}
