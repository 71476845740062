import { BuildCircle } from '@mui/icons-material'
import { TreeItem as TreeItemComponent, treeItemClasses } from '@mui/x-tree-view'

import { type TreeItem, type TreeNode, type TreeStory } from '../../helpers/generated.js'
import { NodeLabel } from './NodeLabel.js'

export type StoryOrNodeProps = {
  item: TreeItem
  navigationDisabled?: boolean
}

export function StoryOrNode(props: StoryOrNodeProps) {
  const { item, navigationDisabled } = props

  return item.type === 'item' ? (
    <Story item={item} navigationDisabled={navigationDisabled} />
  ) : (
    <Node item={item} navigationDisabled={navigationDisabled} />
  )
}

export type NodeProps = {
  item: TreeNode
  navigationDisabled?: boolean
}

export function Node(props: NodeProps) {
  const { item, navigationDisabled } = props

  return (
    <TreeItemComponent key={item.uid} nodeId={item.uid} label={<NodeLabel item={item} />}>
      {item.children.map(childItem => {
        if (childItem.type === 'item') {
          return <Story key={childItem.uid} item={childItem} navigationDisabled={navigationDisabled} />
        }

        return (
          <TreeItemComponent key={childItem.uid} nodeId={childItem.uid} label={<NodeLabel item={childItem} />}>
            {childItem.children.map(descendantItem => {
              return (
                <StoryOrNode key={descendantItem.uid} item={descendantItem} navigationDisabled={navigationDisabled} />
              )
            })}
          </TreeItemComponent>
        )
      })}
    </TreeItemComponent>
  )
}

export type StoryProps = {
  item: TreeStory
  navigationDisabled?: boolean
}

export function Story(props: StoryProps) {
  const { item, navigationDisabled } = props

  return (
    <TreeItemComponent
      nodeId={item.slug}
      disabled={navigationDisabled}
      label={item.title}
      icon={<BuildCircle />}
      sx={{
        color: theme => theme.vars.palette.primary.main,
        [`.${treeItemClasses.content}`]: {
          padding: '5px',
        },
      }}
    />
  )
}
