import { ChevronLeft, ChevronRight, FormatColorFill, Inbox } from '@mui/icons-material'
import {
  Box,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Tooltip,
  darken,
} from '@mui/material'
import { ThemePreview } from '@workwave-tidal/devtools'
import { type TidalThemeProviderProps } from '@workwave-tidal/tidal/styles'
import { Drawer } from './components/Drawer.js'
import { WarnTooltip } from './components/WarnTooltip.js'
import { useThemeSidebar } from './hooks/useThemeSidebar.js'

type ThemeVersion = TidalThemeProviderProps['themeVersion']

const themeVersionList: ThemeVersion[] = ['v0', 'v1', 'v2']

const EmptyBox = () => <Box sx={{ width: 0 }} />

export function ThemeSidebar() {
  const {
    themeVersion,
    themeModified,
    onResetTheme,
    //
    onToggleSidebar,
    sidebarOpen,
    //
    versionMenuAnchorEl,
    versionMenuOpen,
    onOpenVersionMenu,
    onCloseVersionMenu,
    onChangeThemeVersion,
  } = useThemeSidebar()

  return (
    <Drawer variant="permanent" open={sidebarOpen} data-id="theme-sidebar-drawer">
      <Box data-testid="vertical-spacer" sx={{ flex: '1 1 auto' }} />

      {/*
        ------------------------------------------------------
        THEME EDITOR
        ------------------------------------------------------
      */}
      {sidebarOpen ? <ThemePreview onClose={onToggleSidebar} /> : <EmptyBox />}

      <Divider data-testid="divider" />

      <List data-testid="actions" sx={{ padding: 0 }}>
        {/*
          ------------------------------------------------------
          RESET MODIFIED THEME BUTTON
          ------------------------------------------------------
        */}

        {themeModified && (
          <>
            <WarnTooltip title={sidebarOpen ? '' : 'Reset Active Theme'} placement="right" arrow>
              <ListItem
                disablePadding
                data-testid="reset-theme"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  padding: 0,
                }}
                onClick={onResetTheme}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: sidebarOpen ? 'initial' : 'center',
                    padding: 0,
                    backgroundColor: th => th.vars.palette.error.dark,
                    '&:hover': th => ({
                      backgroundColor: darken(th.palette.error.dark, 0.3),
                    }),
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: 'center',
                      px: 1,
                      color: th => th.vars.palette.tidalColors.cloudGray[50],
                    }}
                  >
                    <Inbox />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: { color: th => th.vars.palette.tidalColors.cloudGray[50] },
                    }}
                    primary="Reset Active Theme"
                    sx={{ opacity: sidebarOpen ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </WarnTooltip>

            <Divider data-testid="divider" />
          </>
        )}

        {/*
          ------------------------------------------------------
          CHANGE THEME VERSION BUTTON
          ------------------------------------------------------
        */}

        <Tooltip title={sidebarOpen ? '' : 'Theme Version'} placement="right" arrow>
          <ListItem
            data-testid="theme-version"
            disablePadding
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: 0,
            }}
            onClick={onOpenVersionMenu}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: sidebarOpen ? 'initial' : 'center',
                px: 2.5,
                padding: 0,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: 'center',
                  px: 1,
                }}
              >
                <Inbox />
              </ListItemIcon>
              <ListItemText primary="Theme Version" sx={{ opacity: sidebarOpen ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </Tooltip>

        {/*
          ------------------------------------------------------
          TOGGLE SIDEBAR BUTTON
          ------------------------------------------------------
        */}

        <Tooltip title={sidebarOpen ? '' : 'Open Editor'} placement="right" arrow>
          <ListItem
            data-testid="toggle-sidebar"
            disablePadding
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: 0,
            }}
            onClick={onToggleSidebar}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: sidebarOpen ? 'initial' : 'center',
                px: sidebarOpen ? 0 : 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: sidebarOpen ? 'flex-end' : 'center',
                  px: 1,
                  width: '100%',
                }}
              >
                {sidebarOpen ? <ChevronLeft /> : <ChevronRight />}
              </ListItemIcon>
              <ListItemText sx={{ opacity: sidebarOpen ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </Tooltip>
      </List>

      {/*
          ------------------------------------------------------
          CHANGE THEME VERSION MENU
          ------------------------------------------------------
        */}

      <Menu
        data-testid="change-theme-version-menu"
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={versionMenuAnchorEl}
        open={versionMenuOpen}
        onClose={onCloseVersionMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 30,
          horizontal: -14,
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            width: 300,
            maxWidth: '100%',

            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: '50%',
              left: -5,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <MenuList>
          <MenuItem
            dense
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
                cursor: 'default',
              },
            }}
          >
            <ListItemIcon>
              <FormatColorFill fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ mr: 2 }}>Theme</ListItemText>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={onChangeThemeVersion}
              value={themeVersion ?? themeVersionList.at(-1)}
            >
              <FormControlLabel
                value="v0"
                control={<Radio size="small" />}
                label="v0"
                componentsProps={{ typography: { variant: 'caption' } }}
              />
              <FormControlLabel
                value="v1"
                control={<Radio size="small" />}
                label="v1"
                sx={{ mr: 0 }}
                componentsProps={{ typography: { variant: 'caption' } }}
              />
              <FormControlLabel
                value="v2"
                control={<Radio size="small" />}
                label="v2"
                sx={{ mr: 0 }}
                componentsProps={{ typography: { variant: 'caption' } }}
              />
            </RadioGroup>
          </MenuItem>
        </MenuList>
      </Menu>
    </Drawer>
  )
}
