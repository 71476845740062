import { SnackMachine } from '@workwave-tidal/components'
import { Router } from '@workwave-tidal/router'
import { GlobalControlsProvider, TidalStoriesProvider } from '@workwave-tidal/stories-helpers'
import { TidalThemeProvider } from '@workwave-tidal/styles'

import { DecoratorsComponent } from '../helpers/parsedTree.js'

import { Layout } from './Layout.js'
import { MDXProvider } from './MDXProvider.js'
import { useSyncRouterToStoriesState } from './atoms/storiesState.js'
import { useCreateTidalStoriesContext } from './hooks/useCreateTidalStoriesContext.js'
import { useRedirectLegacyStoryUrl } from './hooks/useRedirectLegacyStoryUrl.js'
import { useSnackMachine } from './hooks/useSnackMachine.js'

export function Root() {
  const { tidalStories, tidalStoriesApi } = useCreateTidalStoriesContext()

  return (
    <TidalThemeProvider>
      <TidalStoriesProvider tidalStories={tidalStories} tidalStoriesApi={tidalStoriesApi}>
        <GlobalControlsProvider>
          <MDXProvider>
            <DecoratorsComponent>
              <Router enableHashLocation>
                <Content />
              </Router>
            </DecoratorsComponent>
          </MDXProvider>
        </GlobalControlsProvider>
      </TidalStoriesProvider>
    </TidalThemeProvider>
  )
}

function Content() {
  // hack to pass a Router reference to the tidal stories store
  useSyncRouterToStoriesState()

  const snackMachine = useSnackMachine()

  // ATTENTION: Remap legacy URLs to the new route-based URLs
  useRedirectLegacyStoryUrl()

  return (
    <>
      <SnackMachine
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        position="fixed"
        snackMachine={snackMachine}
        width="600px"
      />

      <Layout />
    </>
  )
}
