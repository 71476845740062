import { Search as SearchIcon } from '@mui/icons-material'
import {
  InputAdornment,
  TextField,
  type TextFieldProps,
  Typography,
  outlinedInputClasses as classes,
} from '@mui/material'

type SearchFieldProps = Pick<TextFieldProps, 'value' | 'onFocus' | 'onChange' | 'autoFocus'> & {
  collapseBorder?: boolean
  endAdornmentLabel?: string
}

export function SearchField(props: SearchFieldProps) {
  const { collapseBorder, endAdornmentLabel, ...textFieldProps } = props

  return (
    <TextField
      {...textFieldProps}
      sx={{
        [`& .${classes.root}`]: {
          [`.${classes.notchedOutline}`]: {
            borderColor: '#eeeeee',
            borderRadius: '10px',

            ...(collapseBorder && {
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0px',
            }),
          },

          [`&.${classes.focused} .${classes.notchedOutline}`]: {
            borderColor: '#d3d3d3',
          },
        },
      }}
      fullWidth
      placeholder="Search Stories"
      autoComplete="off"
      InputProps={{
        endAdornment: endAdornmentLabel && (
          <InputAdornment position="end">
            <Typography
              variant="subtitle2"
              sx={{
                p: 0.5,
                border: theme => `1px solid ${theme.vars.palette.divider}`,
                fontFamily: 'monospace',
                borderRadius: '4px',
              }}
            >
              {endAdornmentLabel}
            </Typography>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  )
}
