import { type MouseEvent, useCallback } from 'react'

import {
  ArrowUpward as ArrowUpwardIcon,
  FilterList as FilterListIcon,
  FilterListOff as FilterListOffIcon,
} from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'

import { expandToSlug } from '../atoms/treeState.js'
import { useChangeFilter } from '../hooks/useChangeFilter.js'
import { useStoryRoute } from '../hooks/useStoryRoute.js'

import { type NodeProps } from './StoryOrNode.js'

export function NodeLabel(props: NodeProps) {
  const {
    item: { title, slug },
  } = props

  const { filter } = useStoryRoute()

  const changeFilter = useChangeFilter()

  const isActive = filter === slug

  const btnDisplay = isActive ? 'flex' : 'none'

  const filterIcon = isActive ? <FilterListOffIcon fontSize="small" /> : <FilterListIcon fontSize="small" />

  const onClickFilter = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      if (isActive) {
        changeFilter('')
      } else {
        changeFilter(slug)
        expandToSlug(slug)
      }
    },
    [changeFilter, isActive, slug],
  )

  const onClickUp = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      changeFilter(slug.split('/').slice(0, -1).join('/'))
    },
    [changeFilter, slug],
  )

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...(!isActive && {
          '&:hover > #btns': {
            display: 'inline-flex',
          },
        }),
      }}
    >
      <Typography sx={{ flexGrow: 1, py: 0.5 }}>{title}</Typography>

      <Box id="btns" sx={{ display: btnDisplay }}>
        {isActive && (
          <Button sx={{ minWidth: 'unset' }} size="small" onClick={onClickUp}>
            <ArrowUpwardIcon fontSize="small" />
          </Button>
        )}
        <Button sx={{ minWidth: 'unset' }} size="small" onClick={onClickFilter}>
          {filterIcon}
        </Button>
      </Box>
    </Box>
  )
}
