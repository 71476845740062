import { PreviewPlaceholder } from '../components/PreviewPlaceholder.js'
import { type SearchResultItem } from '../components/atoms/searchResults.js'
import { type TreeItem, getComponent, getPlaceholder, storiesTree } from './generated.js'

export {
  Decorators as DecoratorsComponent,
  getLeftComponents,
  getTopComponents,
  getRightComponents,
  getBottomComponents,
} from './generated.js'

export function getStoriesTree(): Readonly<Record<string, TreeItem>> {
  return storiesTree
}

function matchItemId(id: string, item: TreeItem) {
  return item.type === 'item' && item.uid === id
}

function matchNodeId(id: string, item: TreeItem) {
  return item.type === 'node' && item.uid === id
}

function matchSlug(slug: string, item: TreeItem) {
  return item.slug === slug
}

export function findBySlug(slug: string) {
  for (const item of getStoryIterator()) {
    if (matchSlug(slug, item)) return item
  }
}

export function findNodeById(id: string) {
  for (const item of getStoryIterator()) {
    if (matchNodeId(id, item)) return item
  }
}

export function findItemById(id: string) {
  for (const item of getStoryIterator()) {
    if (matchItemId(id, item)) return item
  }
}

export function findNodeByIdWithPath(id: string) {
  return findItemWithPath(item => matchNodeId(id, item))
}

export function findBySlugWithPath(slug: string) {
  return findItemWithPath(item => matchSlug(slug, item))
}

export function storyExists(slug: string): boolean {
  return !!findBySlug(slug)
}

export function getStoryComponent(slug: string) {
  const id = findBySlug(slug)?.uid ?? ''
  if (id) return getComponent(id)
}

export function getTreeTopLevelIds() {
  return Object.values(storiesTree).map(storyRoot => storyRoot.uid)
}

export function getPlaceholderComponent() {
  return getPlaceholder() ?? PreviewPlaceholder
}

function findItemWithPath(fn: (item: TreeItem) => boolean): { item: TreeItem; path: TreeItem[] } | undefined {
  const items = getStoryIterator()
  items.next() // skip Root

  const path: TreeItem[] = []

  for (const item of items) {
    // pop nodes that arent our parent
    for (let i = path.length - 1; i >= 0; i--) {
      if (path[i].children.includes(item)) break
      path.pop()
    }

    if (item.type === 'node') {
      path.push(item)
    }

    if (fn(item)) {
      return {
        item,
        path,
      }
    }
  }
}

// ------------------------------------------------
// ------------------------------------------------

export type SearchMatchToken = {
  value: string
  match?: boolean
}

export type BaseSearchMatch = {
  id: string
  title: string
  nodePath: string[]
}

export interface SearchMatch extends BaseSearchMatch {
  packageName: string
  componentName: string
  matchingTags: string[]
  titleTokens: SearchMatchToken[]
  componentNameTokens?: SearchMatchToken[]
  nodePathTokens: SearchMatchToken[][]
}
export interface GroupedSearchMatch {
  [component: string]: SearchMatch[]
}

// ------------------------------------------------
// ------------------------------------------------

function treeItemToSearchResultItem(node: TreeItem, nodePath: string[]): SearchResultItem {
  return {
    id: node.type === 'item' ? node.slug : node.uid,
    path: nodePath.join('/'),
    tags: node.displayTags.slice(0, 2),
    type: node.type,
    label: node.title,
  }
}

function searchStory(search: string, node: TreeItem, nodePath: string[], matches: SearchResultItem[]) {
  const hasMatchingTags = node.searchTags.some(tag => tag.includes(search))

  const childMatches: SearchResultItem[] = []

  const childPath = nodePath.concat(node.title)
  node.children.forEach(child => {
    searchStory(search, child, childPath, childMatches)
  })

  if (hasMatchingTags || childMatches.length > 0) {
    if (node.type === 'item') {
      matches.push(treeItemToSearchResultItem(node, nodePath))
    } else {
      const match = treeItemToSearchResultItem(node, childPath)
      const keepNode = childMatches.some(x => x.type === 'item' && x.path === match.path)

      if (keepNode) matches.push(match)

      matches.push(...childMatches)
    }
  }
}

export function searchStories(search: string): SearchResultItem[] {
  const matches: SearchResultItem[] = []

  for (const node of Object.values(getStoriesTree())) {
    searchStory(search.toLowerCase(), node, [], matches)
  }

  matches.sort((a, b) => a.path.localeCompare(b.path))
  return matches
}

// ------------------------------------------------
// ------------------------------------------------

export function* getStoryIterator(item: TreeItem = getTreeRoot()): Generator<TreeItem, TreeItem | undefined> {
  yield item
  if (item.type === 'node') {
    for (const child of item.children) {
      yield* getStoryIterator(child)
    }
  }
  return
}

function getTreeRoot(): TreeItem {
  return {
    uid: 'root',
    slug: 'root',
    type: 'node',
    title: 'Root',
    children: Object.values(getStoriesTree()),
    searchTags: [],
    displayTags: [],
  }
}
