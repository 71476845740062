// Import the license injector before mounting any react component
import './MUILicense.js'

import { Box } from '@mui/material'
import { useTidalStoriesApi } from '@workwave-tidal/stories-helpers'
import { type ReactNode, useEffect } from 'react'

import { printTidalVersions } from '@workwave-tidal/base'
import { ThemePreviewProvider } from '@workwave-tidal/devtools'
import { useActiveTheme } from './ThemeSidebar/themeStore.js'

type Props = { children: ReactNode }

// biome-ignore lint/style/noDefaultExport: The default export is required by Tidal Stories
export default function MainDecorator(props: Props) {
  const { children } = props

  const { updateGlobalControlsConfig } = useTidalStoriesApi()

  const themeVersion = useActiveTheme()

  useEffect(() => {
    updateGlobalControlsConfig({
      top: { hideTabs: true, disableResize: false, initialSize: 100, minSize: 100 },
      right: { hideTabs: true, disableResize: false, initialSize: 700, minSize: 150 },
      bottom: { hideTabs: false, disableResize: false, initialSize: 400, minSize: 150 },
      left: { hideTabs: true, disableResize: true },
    })
  }, [updateGlobalControlsConfig])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ThemePreviewProvider themeVersion={themeVersion} style={{ height: '100%' }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            height: '100%',
          }}
        >
          {children}
        </Box>
      </ThemePreviewProvider>
    </Box>
  )
}

console.log('')
console.log('---------------------------------------------')
printTidalVersions()
console.log('---------------------------------------------')
console.log('')
