import { useLocation } from '@workwave-tidal/router'
import { useCallback } from 'react'

import { useStoryRoute } from './useStoryRoute.js'

export function useChangeFilter() {
  const { story } = useStoryRoute()
  const [, navigate] = useLocation()

  return useCallback(
    (slug: string) => {
      // FIXME:
      // ATTENTION: this won't work once we disable hash navigation for tidal preview
      const hash = document.location.hash
      const queryParamsIndex = hash.indexOf('?')
      const hasQueryParams = queryParamsIndex !== -1
      const queryParams = hasQueryParams ? hash.slice(queryParamsIndex) : ''

      if (slug !== '') {
        navigate(`/${slug}/${story}${queryParams}`)
      } else {
        navigate(`/${story}${queryParams}`)
      }
    },
    [navigate, story],
  )
}
