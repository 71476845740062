import { Box } from '@mui/material'
import { Component, type ReactNode, Suspense, useMemo } from 'react'

import { getPlaceholderComponent, getStoryComponent } from '../helpers/parsedTree.js'
import { PreviewFallback } from './PreviewFallback.js'
import { useStoryRoute } from './hooks/useStoryRoute.js'

export function Preview() {
  const { story: activePreview } = useStoryRoute()

  const SandboxOutlet = useMemo(() => {
    const PreviewComponent = activePreview ? getStoryComponent(activePreview) : getPlaceholderComponent()

    return PreviewComponent ?? getPlaceholderComponent()
  }, [activePreview])

  return (
    <Box
      data-testid="tidal-stories_preview"
      sx={{
        maxHeight: '100%',
        overflow: 'auto',
        display: 'flex',
        flexGrow: 1,
      }}
    >
      <Suspense fallback={<PreviewFallback />}>
        <ErrorBoundary>
          <SandboxOutlet />
        </ErrorBoundary>
      </Suspense>
    </Box>
  )
}

type ErrorBoundaryProps = {
  children: ReactNode
}

type ErrorBoundaryState = {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '8px' }}>
          <h2>Something went wrong. See the console for error info</h2>
        </div>
      )
    }

    return this.props.children
  }
}
