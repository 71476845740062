import { proxy, snapshot, subscribe, useSnapshot } from '@workwave-tidal/store'

const localStorageId = 'tidal-stories/search-results'

type SearchResultItemCore = {
  id: string
  tags: string[]
  path: string
  label: string
}

export type SearchResultItemCategory = SearchResultItemCore & {
  type: 'node'
}

export type SearchResultItemStory = SearchResultItemCore & {
  type: 'item'
}

export type SearchResultItem = SearchResultItemCategory | SearchResultItemStory

export type SearchResults = {
  favorites: SearchResultItemStory[]
  recent: SearchResultItemStory[]
}

type SearchResultsAtom = { results: SearchResults; version: number }

// ---------------------------------------------------------------------------
// Default values
// ---------------------------------------------------------------------------
const defaultSearchResults: SearchResults = {
  favorites: [],
  recent: [],
}

// ------------------------------------
// Search results atom
// ------------------------------------

export const searchResultsAtom = proxy<SearchResultsAtom>({
  results: defaultSearchResults,
  version: 1,
})

// ---------------------------------------------------------------------------
// Write functions
// ---------------------------------------------------------------------------

export function resetSearchResults() {
  searchResultsAtom.results = defaultSearchResults
}

// ------------------------------------
// Write to Local Storage
// ------------------------------------

function loadFromStorage() {
  const item = localStorage.getItem(localStorageId)

  if (item) {
    try {
      const stored = JSON.parse(item) as SearchResultsAtom

      if (stored.version === 1) {
        searchResultsAtom.results = stored.results
      }
    } catch {
      //
    }
  }
}

function saveToStorage(atom: SearchResultsAtom) {
  localStorage.setItem(localStorageId, JSON.stringify(snapshot(atom)))
}

loadFromStorage()

subscribe(searchResultsAtom, () => {
  saveToStorage(searchResultsAtom)
})

// ------------------------------------
// Recent API
// ------------------------------------

export function addToRecent(story: SearchResultItemStory) {
  const { recent, favorites } = searchResultsAtom.results
  // favorites stories should not be in recent
  if (favorites.find(item => item.id === story.id)) return

  removeFromRecent(story)
  recent.unshift(story)
  if (recent.length > 10) recent.pop()
}

export function removeFromRecent(story: SearchResultItemStory) {
  const { recent } = searchResultsAtom.results
  const idx = recent.findIndex(item => item.id === story.id)
  if (idx !== -1) recent.splice(idx, 1)
}

export function useRecentResults() {
  return useSnapshot(searchResultsAtom.results).recent as SearchResultItemStory[]
}

// ------------------------------------
// Favorites API
// ------------------------------------

export function addToFavorites(story: SearchResultItemStory) {
  const { favorites } = searchResultsAtom.results
  removeFromRecent(story)
  removeFromFavorites(story)
  favorites.push(story)
}

export function removeFromFavorites(story: SearchResultItemStory) {
  const { favorites } = searchResultsAtom.results
  const idx = favorites.findIndex(item => item.id === story.id)
  if (idx !== -1) favorites.splice(idx, 1)
}

export function useFavoriteResults() {
  return useSnapshot(searchResultsAtom.results).favorites as SearchResultItemStory[]
}
