import { proxy } from '@workwave-tidal/store'
import { TidalThemeProviderProps } from '@workwave-tidal/tidal/styles'

export type ThemeVersion = Required<TidalThemeProviderProps['themeVersion']>

type Store = {
  version: ThemeVersion
}

const store = proxy<Store>({
  version: 'v2',
})

export function setActiveTheme(version: ThemeVersion) {
  store.version = version
}

export function useActiveTheme(): ThemeVersion {
  return store.version
}
