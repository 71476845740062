import { motion } from 'framer-motion'
import { useEffect, useLayoutEffect, useRef } from 'react'

import { Box, Paper } from '@mui/material'

import { type UpdateFunction } from '@workwave-tidal/hooks/useQueryState'

import { useTidalStoriesStateField } from '../atoms/storiesState.js'
import { useResizeNavigation } from '../hooks/useResizeNavigation.js'
import { useSearchDialogVisibility } from '../hooks/useSearchDialogVisibility.js'

import { NavigationCollapsed } from './NavigationCollapsed.js'
import { NavigationControls } from './NavigationControls.js'
import { NavigationTree } from './NavigationTree.js'
import { ResizeNavigation } from './ResizeNavigation.js'
import { ScrollableContainer } from './ScrollableContainer.js'
import { SearchDialog } from './SearchDialog/SearchDialog.js'
import { SearchField } from './SearchField.js'

export type Props = {
  sidebarPosition: 'row' | 'row-reverse'
  onChangeSidebarPosition: UpdateFunction<'row' | 'row-reverse'>
}

export function Navigation(props: Props) {
  const { sidebarPosition, onChangeSidebarPosition } = props

  const [navigationDisabled] = useTidalStoriesStateField('navigationDisabled')
  const [disableKeybindings] = useTidalStoriesStateField('disableKeybindings')

  const rSidebarPosition = useRef(sidebarPosition)
  useLayoutEffect(() => void (rSidebarPosition.current = sidebarPosition), [sidebarPosition])

  const [collapsed, setCollapsed] = useTidalStoriesStateField('navigationCollapsed')
  const rCollapsed = useRef(collapsed)
  useLayoutEffect(() => void (rCollapsed.current = collapsed), [collapsed])

  const [fullScreen, setFullScreen] = useTidalStoriesStateField('fullScreen')
  const rFullScreen = useRef(fullScreen)
  useLayoutEffect(() => void (rFullScreen.current = fullScreen), [fullScreen])

  const { mWidth, setNavigationWidth } = useResizeNavigation(sidebarPosition)

  const { onCloseSearchDialog, onShowSearchDialog, searchDialogVisible } = useSearchDialogVisibility()

  useEffect(() => {
    if (disableKeybindings) return

    function handleOnKeyDown(event: KeyboardEvent) {
      const fromAnyInput = event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement

      if (fromAnyInput) return

      const noModifiers = !event.metaKey && !event.ctrlKey && !event.altKey && !event.shiftKey

      if (event.key.toLowerCase() === 's' && noModifiers) {
        event.preventDefault()
        setCollapsed(!rCollapsed.current)
      }

      if (event.key.toLowerCase() === 'f' && noModifiers) {
        event.preventDefault()
        setFullScreen(!rFullScreen.current)
      }

      if (event.key.toLowerCase() === 'k' && (event.metaKey || event.ctrlKey)) {
        event.preventDefault()
        onShowSearchDialog()
      }
    }

    window.addEventListener('keydown', handleOnKeyDown)

    return () => {
      window.removeEventListener('keydown', handleOnKeyDown)
    }
  }, [setCollapsed, setFullScreen, onShowSearchDialog, disableKeybindings])

  if (fullScreen) {
    return (
      <SearchDialog open={searchDialogVisible} onClose={onCloseSearchDialog} navigationDisabled={navigationDisabled} />
    )
  }

  if (collapsed) {
    return <NavigationCollapsed sidebarPosition={sidebarPosition} setCollapsed={setCollapsed} />
  }

  return (
    <Paper
      elevation={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        margin: 1,
        overflow: 'hidden',
        position: 'relative',
        userSelect: 'none',
      }}
      data-testid="tidal-stories_navigation"
    >
      <NavigationControls
        setCollapsed={setCollapsed}
        setFullScreen={setFullScreen}
        sidebarPosition={sidebarPosition}
        onChangeSidebarPosition={onChangeSidebarPosition}
      />

      <motion.div
        style={{
          width: mWidth,
          flex: 1,
          display: 'flex',
          minWidth: '350px',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Box p={1}>
          <SearchField endAdornmentLabel="ctrl+k" onFocus={onShowSearchDialog} />
        </Box>

        <ScrollableContainer>
          <NavigationTree navigationDisabled={navigationDisabled} />
        </ScrollableContainer>
      </motion.div>
      <ResizeNavigation mWidth={mWidth} sidebarPosition={sidebarPosition} setNavigationWidth={setNavigationWidth} />
      <SearchDialog open={searchDialogVisible} onClose={onCloseSearchDialog} navigationDisabled={navigationDisabled} />
    </Paper>
  )
}
