import { Dialog, List, Stack } from '@mui/material'
import { useEffect, useRef } from 'react'

import { useFavoriteResults, useRecentResults } from '../../atoms/searchResults.js'
import { ScrollableContainer } from '../ScrollableContainer.js'
import { SearchField } from '../SearchField.js'

import { useKeyboardNavigation } from './hooks/useKeyboardNavigation.js'
import { useSearchStories } from './hooks/useSearchStories.js'

import { FavoriteResults } from './components/FavoriteResults.js'
import { NoResults } from './components/NoResults.js'
import { RecentResults } from './components/RecentResults.js'
import { SearchResult } from './components/SearchResult.js'

type Props = {
  open: boolean
  onClose: () => void
  navigationDisabled?: boolean
}

export function SearchDialog(props: Props) {
  const { open, navigationDisabled } = props

  const { noResults, searchValue, searchResults, handleOnClose, onChangeSearchValue } = useSearchStories(props)

  const ref = useRef<HTMLDivElement>(null)

  // biome-ignore lint/correctness/useExhaustiveDependencies: the effect must run every time `searchResults` changes.
  useEffect(() => {
    ref.current?.scrollTo(0, 0)
  }, [searchResults])

  const showResultList = searchResults.length > 0
  const favoriteItems = useFavoriteResults()
  const recentItems = useRecentResults()

  const { active: activeItemId } = useKeyboardNavigation({
    favorites: favoriteItems,
    recent: recentItems,
    search: searchResults,
    navigationDisabled,
    dialogOpen: open,
  })

  return (
    <Dialog open={open} onClose={handleOnClose} disableRestoreFocus>
      <Stack width={600} height={600}>
        <SearchField
          value={searchValue}
          onChange={onChangeSearchValue}
          autoFocus
          collapseBorder
          endAdornmentLabel="esc"
        />

        <ScrollableContainer ref={ref}>
          {showResultList ? (
            <List>
              {searchResults.map(item => (
                <SearchResult
                  key={item.id}
                  item={item}
                  onClose={handleOnClose}
                  navigationDisabled={navigationDisabled}
                  activeItemId={activeItemId}
                />
              ))}
            </List>
          ) : noResults ? (
            <NoResults search={searchValue} />
          ) : (
            <Stack p={2}>
              <FavoriteResults
                onCloseSearchDialog={handleOnClose}
                navigationDisabled={navigationDisabled}
                items={favoriteItems}
                activeItemId={activeItemId}
              />
              <RecentResults
                onCloseSearchDialog={handleOnClose}
                navigationDisabled={navigationDisabled}
                items={recentItems}
                activeItemId={activeItemId}
              />
            </Stack>
          )}
        </ScrollableContainer>
      </Stack>
    </Dialog>
  )
}
