import { Box, type SxProps, type Theme } from '@mui/material'
import { type ReactNode, forwardRef } from 'react'

type Props = {
  sx?: SxProps<Theme>
  children: ReactNode
}
export const ScrollableContainer = forwardRef<HTMLDivElement, Props>(function ScrollableContainer(props, ref) {
  const { sx = {}, children } = props

  return (
    <Box
      sx={{
        flex: '1 0 auto',
        position: 'relative',
        ...sx,
      }}
    >
      <Box
        ref={ref}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  )
})
